
.edit-register-form-link{
	color: $color-bnn;
	text-direction: underline;
}
.text-gray-medium-2{
	color: $color-bnn-gray-medium-2;
}
.btn-bnn-default{
  padding: 0 1.25rem !important
  font-size: 1rem !important
}
add-to-calendar-button#add-to-calendar-button::part(atcb-button){
	background-color: #fff
	box-shadow: 0 0 0 1px $color-bnn-gray-light
	font-size: 0.875rem
	margin: 0
	border: none

	// v-btn
	align-items: center;
	border-radius: 50%;
	display: inline-flex;
	flex: 0 0 auto;
	font-weight: 500;
	letter-spacing: 0.0892857143em;
	justify-content: center;
	outline: 0;
	position: relative;
	text-decoration: none;
	text-indent: 0.0892857143em;
	text-transform: uppercase;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	vertical-align: middle;
	white-space: nowrap;
	height: 48px;
    width: 48px;

	transition: 0.2s ease-in-out;
	&:hover {
		width: 48px
		background-color: $color-bnn-light
		transition: 0.2s ease-in-out;
		box-shadow: 0 0 0 1px $color-bnn-mid
	}
}

.btn-animate:after{
	position: absolute;
    box-shadow: 0 0 1px 2px $color-bnn-mid;
    border-top: 10px solid $color-bnn-mid;
    border-right: 20px solid $color-bnn-mid;
    border-bottom: 10px solid $color-bnn-mid;
    border-left: 20px solid $color-bnn-mid;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 8px;
    animation: 1.5s ease-in-out infinite btn-primary-effect;
    content: ""
}

@keyframes btn-primary-effect {
	0%{
		transform:scale(1);
		opacity:0
	}
	50%{
		opacity: 0.75
	}
	to{
		transform: scale(1.1, 1.3);
		opacity:0
	}
}

.pre-register-actions-container {
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    gap: 16px
}
